@font-face {
  font-family: 'Oxygen-Light';
  src: url('Oxygen-Light.eot');
  src: url('Oxygen-Light.eot?#iefix') format('embedded-opentype'),
    url('Oxygen-Light.ttf') format('truetype'),
    url('Oxygen-Light.woff') format('woff'),
    url('Oxygen-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oxygen-Regular';
  src: url('Oxygen-Regular.eot');
  src: url('Oxygen-Regular.eot?#iefix') format('embedded-opentype'),
    url('Oxygen-Regular.ttf') format('truetype'),
    url('Oxygen-Regular.woff') format('woff'),
    url('Oxygen-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oxygen-Bold';
  src: url('Oxygen-Bold.eot');
  src: url('Oxygen-Bold.eot?#iefix') format('embedded-opentype'),
    url('Oxygen-Bold.ttf') format('truetype'),
    url('Oxygen-Bold.woff') format('woff'),
    url('Oxygen-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@import '../../css/breakpoint.scss';

.rs-holder {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 460px);
}

.mc-ads-result {
  display: inline-block;
  width: 100%;
  padding: 1rem 1.5rem;
  position: relative;

  .m-two-cols,
  .m-three-cols,
  .m-four-cols {
    float: left;
    display: block;
    width: 100%;
  }

  .m-two-cols,
  .m-three-cols {
    display: none;
  }

  .ads-card-col {
    width: calc(25% - 0.9rem);
    display: block;
    float: left;

    .ads-card ~ .ads-card {
      margin-top: 0.5rem;
    }

    & ~ .ads-card-col {
      margin-left: 1.2rem;
    }

    &.card-col-0 .card-2,
    &.card-col-1 .card-2 {
      // display: none;
    }
  }

  &.is-loading {
    padding-bottom: 128px;

    .mc-loading {
      display: block;
      width: 100%;
      float: left;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 128px;
    }
  }
}

.--no-result {
  text-align: center;
  .row {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1025px) {
  .mc-ads-result {
    .m-three-cols {
      display: block;
    }
    .m-two-cols,
    .m-four-cols {
      display: none;
    }
    .ads-card-col {
      width: calc(33.333% - 0.8rem);
    }
  }
}

@media screen and (max-width: 767px) {
  .mc-ads-result {
    .m-three-cols,
    .m-four-cols {
      display: none;
    }

    .m-two-cols {
      display: block;
    }

    .ads-card-col {
      width: calc(50% - 0.5rem);

      & ~ .ads-card-col {
        margin-left: 1rem;
      }

      &.card-col-0 .card-2,
      &.card-col-1 .card-2 {
        display: block;
      }

      &.card-col-2 {
        // display: none;
      }
    }
  }
}

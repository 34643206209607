@import '../../css/breakpoint.scss';
$colSpacing: 0.85rem;

.wrap-ads-filter {
  width: 100%;
  display: inline-block;
  position: relative;
  padding-left: $colSpacing;
  padding-right: $colSpacing;

  .lb-title {
    color: #000000;
    font-family: var(--app-font-bold);
    padding-left: calc(0.2rem + 7px);
    margin-bottom: 0.4rem;
    display: block;
    font-size: .9rem;
  }

  .mc-dropdown {
    padding-left: 0.2rem;
    float: left;
    border-radius: 6px;
    // flex-shrink: 1;
  }

  .row {
    float: left;
    display: flex;
    width: 100%;

    & ~ .row {
      margin-top: 0.85rem;
    }

    .col {
      width: 100%;
      float: left;
      display: block;

      &.col-submit {
        display: inline-flex;
        justify-content: center;
      }

      * {
        width: 100%;
      }

      .btn-clear-search {
        width: auto;
        min-width: 20%;
        padding-left: 2em;
        padding-right: 2em;
      }
    }
  }

  .dropdown-ads-industry {
    // width: 100%;
    margin-left: 0;
  }

  &.hide-submit .row-submit {
    opacity: 0;
    visibility: hidden;
  }

  &.template-3 {
    @media screen and (min-width: $breakpoint_phone) {
      .row {
        &:nth-of-type(1) {
          width: 100%;

          .col ~ .col {
            margin-left: $colSpacing;
          }
        }

        &:nth-of-type(2) {
          padding-right: 0.45rem;
        }

        &:nth-of-type(3) {
          padding-left: 0.45rem;
        }

        &:nth-of-type(2),
        &:nth-of-type(3) {
          .col {
            width: 100%;
          }
        }
      }
    }
  }

  &.template-4 {
    @media screen and (min-width: $breakpoint_phone) {
      .row {
        &:nth-of-type(1),
        &:nth-of-type(2) {
          width: 100%;
        }

        &:nth-of-type(1) {
          .col ~ .col {
            margin-left: $colSpacing;
          }
        }

        &:nth-of-type(2) {
          margin-top: $colSpacing;
        }

        &:nth-of-type(3) {
          float: right;
          width: 240px;
          flex-direction: column;
          position: absolute;
          top: 0;
          right: 0;

          .col:nth-child(2) {
            margin-top: $colSpacing;
          }
        }
      }
    }
  }

  .btn-banner {
    border: none;
    appearance: none;
    background-color: #000;
    color: #fff;
    height: 2rem;
    line-height: 2rem;
    font-size: 0.9rem;
    cursor: pointer;
    padding: 0;
    outline: none;
    &.disabled,
    &:disabled {
      background-color: #666666;
    }

    &.btn-search-ads {
      margin-left: 0.85rem;
    }
  }
}

@media screen and (max-width: $breakpoint_phone) {
  .wrap-ads-filter {
    width: 90%;
    min-width: 185px;
    max-width: 320px;

    &.template-3,
    &.template-4 {
      .row {
        width: 100%;
        display: inline-block;
      }
    }

    .row {
      display: inline-block;
      width: 100%;

      & ~ .row,
      & .col ~ .col {
        margin-top: 0.65rem;
      }

      .col {
        width: 100%;
      }
    }

    .lb-title {
      font-size: 1.5rem;
    }

    .btn-clear-search {
      min-width: 50% !important;
      font-size: 1.5rem;
      height: auto;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      margin-top: 0.25rem;
    }

    .btn-search-ads {
      // width: 50% !important;
      margin: auto;
      // margin-left: 25%;
    }
  }
}

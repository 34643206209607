@import './fonts/fonts.scss';
@import './breakpoint.scss';
@import './animation.scss';

:root {
  --app-background: #363636;
  --app-fontsize: 20px;
  --app-font-regular: 'Oxygen-Regular';
  --app-font-light: 'Oxygen-Light';
  --app-font-bold: 'Oxygen-Bold';
  --app-font-color: #fff;
}

body,
html {
  font-size: var(--app-fontsize);
  font-family: var(--app-font-regular);
  margin: 0;
  padding: 0;
  background-color: var(--app-background);
  color: var(--app-font-color);
  overflow-x: hidden;
}

html {
  overflow: auto;
}

body {
  display: block;
  float: left;
  width: 100%;
  background-color: #464646;
}

body,
html,
body * {
  box-sizing: border-box;
}

#mc-app-root {
  margin: auto;
  max-width: 1920px;
}

// #mc-app-root {
input,
textarea {
  font-family: var(--app-font-regular);
}

.mc-page {
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  display: block;
  padding: 0;
  max-width: 1920px;
  margin: 0 auto;
}

.app-wrapper {
  background-color: var(--app-background);
  float: left;
  width: 100%;
}

section {
  width: 100%;
  float: left;
  clear: both;
}

.lb-page-name,
h2 {
  font-size: 2.4rem;
  font-family: var(--app-font-light);
  border-bottom: 2px solid #fff;
}

.btn-white {
  font-family: var(--app-font-bold);
  height: 2rem;
  text-align: center;
  appearance: none;
  border: 1px solid #fff;
  color: #fff;
  background-color: transparent;
  font-size: 1rem;
  cursor: pointer;
}

@media screen and (max-width: $breakpoint_phone) {
  :root {
    --app-fontsize: 12px;
  }

  .app-page {
    //   margin-top: 45px;
  }

  .mc-page {
    margin-top: 48px;
  }

  .btn-white {
    font-size: 1.3rem;
  }
}
// }

.page-loading {
  position: fixed;
  z-index: 1001;
  background-color: var(--app-background);
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &.hideLoading {
    @extend .anim-fade-out;
  }

  .mc-loading {
    margin: auto;
  }
}

mc-image-viewer {
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: #000;
}

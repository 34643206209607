@import '../../css/breakpoint.scss';

.mc-dropdown{
    background-image: url('../../images/ic-dropdown.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) center;
    background-size: auto .75rem;
    background-color: #fff;
    height: 2rem;

    @media screen and (max-width:$breakpoint_phone){
        height: 2.5rem;
    }
}
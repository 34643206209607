@import '../../css/breakpoint.scss';

.ads-linking-cta {
  width: 100%;
  position: relative;
  display: block;
  float: left;
  text-align: center;
  color: #fff;
  cursor: pointer;
  height: 6rem;
  font-family: var(--app-font-light);
  outline: none;
  border: none;
  appearance: none;
  background-color: rgba(40, 40, 40, 0.5);
  overflow: hidden;

  .dim {
    background-color: rgba(40, 40, 40, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    transform: none;
    border: none;
  }

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: '';
    background-image: url('../../images/bg-landing.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 30rem;
  }

  @mixin translateTop($childIndex, $topValue) {
    &:nth-child(#{$childIndex})::before {
      top: #{$topValue};
    }
  }

  @include translateTop(1, 0rem);
  @include translateTop(2, -6rem);
  @include translateTop(3, -12rem);
  @include translateTop(4, -18rem);
  @include translateTop(5, -24rem);
  @include translateTop(6, -30rem);

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: '';
    z-index: 0;
    opacity: 0.3;
  }
  // Animation
  &,
  & .dim,
  &::after {
    transition: background 0.2s linear, opacity 0.2s linear;
  }

  &:hover {
    background-color: transparent;
    .dim {
      background-color: rgba(0, 0, 0, 0);
    }
    &::after {
      opacity: 0.6;
    }
  }

  span {
    border-bottom: 2px solid #fff;
    padding-bottom: 0px;
    position: relative;
    z-index: 2;
    font-size: 2.4rem;
    transform: translateY(-0.15em);
    display: inline-block;
  }
}

@media screen and (max-width: $breakpoint_phone) {
  .ads-linking-cta {
    background-color: transparent;
    .dim {
      background-color: transparent;
    }

    &::after {
      opacity: 0.6;
    }

    &:hover {
      &::after {
        opacity: 0.6;
      }
    }
  }
}

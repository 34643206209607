.mc-checkbox {
  width: 100%;
  position: relative;
  padding: 0;

  input {
    visibility: hidden;
    position: absolute;
    left: 0;
    z-index: 0;
    top:0;
  }

  input[type='checkbox']:checked + label {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 80%;
  }

  label {
    position: absolute;
    width: 100%;
    height: 100%;
    min-width: 1rem;
    min-height: 1rem;
    left: 0;
    top: 0;
    background-color: #ffffff;
    cursor: pointer;
  }

  .cb-text {
    font-size: 0.7rem;
    float: left;
  }
}

@import '../../css/animation.scss';

.mc-page {
//   @extend .anim-translate-to-bottom;
  // @extend .anim-fade-in;
  @extend .anim-fade-in;
  min-height: calc(100vh - 82px);
  animation-duration: 300ms;
  animation-timing-function: linear;
}

.mc-textarea {
  display: inline-block;
  font-size: 0;
  min-width: 60px;

  .mc-input__holder {
    background-color: #595959;
    display: block;
    width: 100%;
    float: left;
  }
  textarea {
    background-color: transparent;
    border: none;
    width: 100%;
    appearance: none;
    float: left;
    font-size: 0.9rem;
    color: #fff;
    padding: 0.3em;
    resize: none;
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
  }

  textarea::placeholder {
    color: #fff;
  }

  .lb-error {
    color: red;
    width: 100%;
    float: left;
    text-align: left;
    font-size: 0.8rem;
    font-style: italic;
    margin-top: 0.2em;
  }
}

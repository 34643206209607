@import '../../css/breakpoint.scss';
@import '../../css/animation.scss';
@import '../../css/layout.scss';

.page-landing {
  section {
    width: 100%;
    float: left;
  }

  .section-logo {
    @extend .flex-col-center-clear-fix;
    text-align: center;

    .imv-logo {
      height: 100%;
      width: auto;
      width: 220px;
      height: 100px;
      position: relative;

      &::before,
      &::after {
        @extend .background-center-contain;
        left: 0;
        top: 0;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
      }

      // Rotating icon
      &::before {
        @extend .anim-rotate-forever;
        background-image: url('../../images/rotate-icons/rotate-icon-red-dot.svg');
        left: 55.6%;
        top: 0;
        background-size: 100%;
        border-radius: 50%;
      }

      // Rotating icon size
      &::before {
        height: 28px;
        width: 28px;
      }

      &:after {
        background-image: url('../../images/rotate-icons/rotate-icon-logo-text.svg');
        background-position: center 13%;
      }
    }

    .lb-title {
      font-size: 1.6rem;
      margin-left: 0.6rem;
      margin-top: 0rem;
      text-align: center;
      display: inline;
      font-family: var(--app-font-light);
    }
  }

  .section-description {
    display: block;
    float: left;
    width: 100%;
    margin-top: 1rem;
    padding: 0 1rem;
    text-align: center;

    p {
      margin: 0;
      padding: 0;
      line-height: 1.5;
      min-height: 0.5em;
      text-align: center;
    }

    // Arrow icon
    &::after {
      content: '';
      @extend .background-center-contain;
      background-image: url('../../images/ic-arrow-down.svg');
      background-size: 100%;
      width: 1.8rem;
      height: 1.8rem;
      display: inline-flex;
      clear: both;
      margin: 1rem auto 1.2rem auto;
    }
  }

  .section-ads-nav {
    position: relative;
    opacity: 0;
    transition: opacity 1000ms linear;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: bottom right;
      // background-image: url('../../images/bg-landing.jpg');
      background-size: cover;
    
    }

    .ads-linking-cta {
      opacity: 0;
      visibility: hidden;
      animation-duration: 1000ms;
    }

    &.show-menu {
      opacity: 1;
      &::before {
        opacity: 1;
      }

      .ads-linking-cta {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .mc-app-footer {
    margin-top: 5rem;

    .wrap-link {
      padding: 30px 10px;
      
      a {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: $breakpoint_phone) {
  .page-landing {
    .mc-main-nav {
      display: none;
    }

    .section-logo {
      .imv-logo {
        width: 130px;
        height: 60px;

        &::before {
          left: 55.5%;
        }
        // Rotating icon size
        &::before {
          height: 16px;
          width: 16px;
        }
      }
    }

    .section-description {
      padding-left: calc((100% - 360px) / 2);
      padding-right: calc((100% - 360px) / 2);
      font-size: 16px;

      // Arrow icon
      &::after {
        margin-top: 1.4rem;
        margin-bottom: 1.7rem;
      }
    }
  }
}

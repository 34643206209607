@import '../../css/breakpoint.scss';

$carouselHeight: 30.7vw;
$sliderItemHeight: 30.7vw;
$carouselMinHeight: 35vh;

.mc-carousel {
  width: 100%;
  height: $carouselHeight;
  position: relative;

  .control-dots {
    display: none;
  }

  .slider .slide {
    background-color: transparent;
    width: 100%;
  }

  &.show-paging {
    display: block;
    height: calc(#{$carouselHeight} + 40px);
    .carousel-holder {
      height: calc(#{$carouselHeight} + 40px);
    }

    .control-dots {
      display: block;
    }
  }

  .carousel-holder {
    position: absolute;
    width: 100%;
    height: $carouselHeight;
    top: 0;
    left: 0;

    .carousel.carousel-slider {
      height: 100%;

      ul.slider {
        height: $sliderItemHeight;
      }
    }

    .control-dots {
      margin: 0;
      .dot {
        height: 1rem;
        width: 1rem;
        outline: none;
      }
    }

    .banner-image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 100%;
      height: 100%;
    }

    .banner-youtube {
      &,
      mc-ytplayer {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }

      iframe{
        margin: 0;
        width: 100%;
        padding: 0;
      }
    }

    .banner-video {
      &,
      mc-player {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        .video-js,
        video {
          width: 100%;
          height: 100%;
          background-color: transparent;
        }
      }
    }

    .banner-podcast {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      left: 5px;
      top: 5px;
      bottom: 5px;
      right: 5px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      mc-player {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0;
        margin: 0;

        .video-js,
        video {
          width: 100%;
          height: 100%;
          background-color: transparent;
        }
      }

      iframe {
        position: relative;
        height: auto;
        margin: 0;
        padding: 0;
        min-width: 30%;
        min-height: 50%;
        transition: all 400ms;
        // background-color: #000;
        width: 100%;
      }

      .loading-holder {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.2);
        padding: 1rem;
      }
    }
  }

  &.type-image {
    height: $carouselHeight;
    min-height: $carouselMinHeight;

    .carousel-holder {
      height: $carouselHeight;
      min-height: $carouselMinHeight;
    }

    &.show-paging {
      min-height: $carouselMinHeight;
      .carousel-holder {
        min-height: $carouselMinHeight;
      }
    }
    .carousel-holder {
      .carousel.carousel-slider {
        ul.slider {
          height: $sliderItemHeight;
          min-height: $carouselMinHeight;
        }
      }
    }
    &.show-paging {
      display: block;
      height: calc(#{$carouselHeight} + 40px);
      min-height: calc(#{$carouselMinHeight} + 40px);
      .carousel-holder {
        height: calc(#{$carouselHeight} + 40px);
        min-height: calc(#{$carouselMinHeight} + 40px);
      }

      .control-dots {
        display: block;
      }
    }
  }
}

@media screen and (max-width: $breakpoint_phone) {
  .mc-carousel {
    $carouselHeight: 50vw;
    $sliderItemHeight: 50vw;

    height: $carouselHeight;

    .carousel-holder {
      height: $carouselHeight;
    }

    &.show-paging {
      height: calc(#{$carouselHeight} + 40px);
      .carousel-holder {
        height: calc(#{$carouselHeight} + 40px);
      }
    }
    .carousel-holder {
      .carousel.carousel-slider {
        height: 100%;

        ul.slider {
          height: $sliderItemHeight;
        }
      }
    }

    &.type-image {
      $carouselMinHeight: 45vh;
      height: $carouselHeight;
      min-height: $carouselMinHeight;

      .carousel-holder {
        height: $carouselHeight;
        min-height: $carouselMinHeight;
      }

      &.show-paging {
        min-height: $carouselMinHeight;
        .carousel-holder {
          min-height: $carouselMinHeight;
        }
      }
      .carousel-holder {
        .carousel.carousel-slider {
          ul.slider {
            height: $sliderItemHeight;
            min-height: $carouselMinHeight;
          }
        }
      }
      &.show-paging {
        display: block;
        min-height: calc(#{$carouselMinHeight} + 30px);
        .carousel-holder {
          min-height: calc(#{$carouselMinHeight} + 30px);
        }

        .control-dots {
          display: block;
        }
      }
    }
  }
}

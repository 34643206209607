@import '../../css/animation.scss';
@import '../../css/breakpoint.scss';

.ads-card {
  @extend .anim-fade-in;
  animation-duration: 200ms;
  position: relative;
  margin-bottom: 15px;

  &,
  .ads-thumb,
  .ads-info,
  img.ads-img {
    width: 100%;
    float: left;
    display: block;
  }

  .ads-thumb {
    background-color: #000;
    position: relative;
    overflow: hidden;
    img.ads-img {
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      &.show {
        opacity: 1;
      }
    }
  }

  .ads-info {
    padding: 0.3rem 0.5rem;
    .row {
      width: 100%;
      float: left;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #fff;
      font-size: 0.9rem;
    }
  }

  .ads-type-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 12%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 135%;
    border: 2px solid #fff;
    border-radius: 200px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 5px;
    z-index: 10;

    &::before {
      content: '';
      position: relative;
      margin-top: 100%;
      display: block;
    }

    &.ads-type-video {
      background-image: url('../../images/video-playback-icons.png');
    }

    &.ads-type-podcast {
      background-image: url('../../images/podcast-icons.png');
    }
  }

  &.loading {
    display: none;

    img.ads-img {
      min-height: 240px;
      opacity: 0;
    }
  }

  @media screen and (max-width: $breakpoint_phone) {
    .ads-info {
      .row {
        font-size: 1.3rem;
      }
    }
  }
}

.app-font-bold {
  font-family: var(--app-font-bold);
  font-weight: bold;
}

.app-font-light {
  font-family: var(--app-font-light);
  font-weight: bold;
}

.app-font-normal {
  font-family: var(--app-font-regular);
  font-weight: bold;
}

.clear-fix {
  float: left;
  clear: both;
}

.flex-col-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.flex-col-center-clear-fix {
  @extend .flex-col-center;
  @extend .clear-fix;
}

.flex-row-center-clear-fix {
  @extend .flex-row-center;
  @extend .clear-fix;
}

.background-center {
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}

.background-bottom-right {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto;
}

.background-center-cover {
  @extend .background-center;
  background-size: cover;
}

.background-center-contain {
  @extend .background-center;
  background-size: contain;
}

.background-bottom-right-cover {
    @extend .background-center;
    background-size: cover;
  }
  
  .background-bottom-right-contain {
    @extend .background-center;
    background-size: contain;
  }

@import '../../css/breakpoint.scss';
@import '../../css/animation.scss';

.mc-main-nav {
  width: 100%;
  display: block;
  float: left;
  text-align: right;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;

  .nav-logo {
    height: 2rem;
    width: auto;
    float: left;
    position: relative;
    margin-left: 1rem;
    @extend .anim-fade-in;
    animation-duration: 300ms;

    img {
      height: 2rem;
      width: 2rem;
      float: left;
    }

    span {
      line-height: 2rem;
      display: inline-block;
      float: left;
      font-size: 1.05rem;
      color: #fff;
      margin-left: 1em;
    }
  }

  .nav-links {
    display: block;
    float: right;

    a {
      display: inline-block;
      margin: 0;
      padding: 0.5rem 0.5em;
      font-size: 0.9rem;
      text-decoration: none;
      color: white;
      cursor: pointer;

      &.activelink {
        color: #00bff3;
      }
    }
  }

  .btn-close-mobile-menu,
  .btn-burger {
    background-size: auto 75%;
    background-position: right center;
    background-repeat: no-repeat;
    display: none;
    background-color: transparent;
    appearance: none;
    border: none;
    outline: none;
    width: 3rem;
    position: relative;
  }

  .btn-burger {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    bottom: 0.5rem;
    background-image: url('../../images/ic-burger.png');
  }

  .btn-close-mobile-menu {
    display: block;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAASCAYAAAC9+TVUAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDAgNzkuMTYwNDUxLCAyMDE3LzA1LzA2LTAxOjA4OjIxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+LUNEtwAAAMVJREFUOI2VU1EVxCAMqwUsYGEWpgULWDgLWJiFWcDCWZiF7OPadxkblPFHG9I2DSIiAmDD7xSZPAAqgAPA0gYBYJ0gKIpNbSIq8xdAGBAkJfh4gL2Tt0KPeQZmJcoPuep1yuBdK0aKmQ6uZvYgaMWq99TrziNaTR/tantFQEQ2Ani0NwS88ldGZBJzZCBHJ//ln+DiSBL6mBqLBC2duGu0y2oHHfZXrescGgqjjzqye4NbFFfbxNy894LFApGU9z/Wfax8AukahFceM3FnAAAAAElFTkSuQmCC');
    height: 3rem;
    float: right;
    margin: 0.5rem 1em;
  }

  @mixin translateTop($childIndex, $topValue) {
    &:nth-child(#{$childIndex})::before {
      top: #{$topValue};
    }
  }

  .ads-linking-cta {

    &::before{
      height: 36rem;
    }

    @include translateTop(2, 0rem);
    @include translateTop(3, -6rem);
    @include translateTop(4, -12rem);
    @include translateTop(5, -18rem);
    @include translateTop(6, -24rem);
    @include translateTop(7, -30rem);
  }

  .mobile-nav {
    position: fixed;
    bottom: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--app-background);
    z-index: 101;

    .ads-linking-cta::before {
      opacity: 0.8;
    }
  }
}

@media screen and(max-width: $breakpoint_phone) {
  .mc-main-nav {
    position: fixed;
    z-index: 100;
    background-color: var(--app-background);

    .nav-links {
      display: none;
    }

    .btn-burger {
      display: block;
    }
  }

  .nav-links {
    margin-top: 60px;
  }
}

// OVERRIDE NAV ACTIVE COLOR TO SEPERATE PAGE
#mc-app-root {
  &.page-digital-ads .mc-main-nav .nav-links {
    a.activelink,
    a:nth-child(1) {
      color: #00aeef;
    }
  }

  &.page-website .mc-main-nav .nav-links {
    a.activelink,
    a:nth-child(2) {
      color: #ff00ff;
    }
  }

  &.page-content .mc-main-nav .nav-links {
    a.activelink,
    a:nth-child(3) {
      color: #f7a18d;
    }
  }

  &.page-bloomr .mc-main-nav .nav-links {
    a.activelink,
    a:nth-child(4) {
      color: #33b560;
    }
  }

  &.page-contact .mc-main-nav .nav-links {
    a.activelink,
    a:nth-child(5) {
      color: #898989;
    }
  }
}

@import '../../css/breakpoint.scss';

.page-contact {
  .mc-page {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .section-top {
    text-align: center;
    margin-top: 2rem;

    span {
      max-width: 590px;
    }
    .lb-page-name {
      opacity: 0.9;
    }
    .lb-page-description {
      display: inline-block;
      margin-top: 1rem;
      line-height: 1.6;
      opacity: 0.9;
    }

    &.--thank-you {
      margin-bottom: 12rem;
      .row {
        width: 100%;
        max-width: 580px;
        text-align: center;
        margin: auto;
      }
    }
  }

  .section-form {
    margin-top: 1.3rem;
    margin-bottom: 10rem;

    .row {
      & ~ .row {
        margin-top: 0.9rem;
      }

      text-align: center;
      .mc-input,
      .mc-textarea,
      .privacy-wrapper {
        margin: auto;
        max-width: 590px;
        width: 100%;
      }

      .mc-textarea textarea {
        height: 13rem;
      }

      .btn-white {
        padding-left: 1em;
        padding-right: 1em;
      }
    }

    .row.row-consent-privacy {
      margin-top: 0.5rem;
      .privacy-wrapper {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        display: inline-block;

        &.error {
          .mc-checkbox {
            border: 1px solid red;
          }
        }
      }

      .mc-checkbox {
        width: 1.5rem;
        height: 1.5rem;
        float: left;
      }

      .ct-privacy {
        width: calc(100% - 2rem);
        display: block;
        float: right;
        text-align: left;
        font-size: 0.7rem;
        margin-top: -0.2rem;
        line-height: 1.4;

        a {
          color: #fff;
        }
      }
    }

    .row.row-submit {
      margin-top: 5rem;
    }
  }
}

// @media screen and (max-width: $breakpoint_phone) {
//   .page-contact {
//     .section-form.is-ios {
//       .zoom-holder {
//       }

//       .scale-holder {
//         transform: scale(0.625);
//         transform-origin: left top;
//         width: calc(100% * 1.6);
//         max-height: 40.5rem;

//         .mc-input input {
//           height: calc(2rem * 1.6);
//         }

//         & ~ .row.row-consent-privacy {
//           margin-top: -12.5rem;
//         }
//       }

//       .row {
//         .mc-input,
//         .mc-textarea {
//           max-width: 944px;
//         }
//       }

//       .mc-input input,
//       .mc-textarea textarea {
//         font-size: 16px;

//         ~ .lb-error {
//           font-size: 10px;
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: $breakpoint_phone) {
  .page-contact {
    .section-form.is-ios {
      //   .zoom-holder {
      //   }

      //   .scale-holder {
      //     transform: scale(0.625);
      //     transform-origin: left top;
      //     width: calc(100% * 1.6);
      //     max-height: 40.5rem;

      //     .mc-input input {
      //       height: calc(2rem * 1.6);
      //     }

      //     & ~ .row.row-consent-privacy {
      //       margin-top: -12.5rem;
      //     }
      //   }

      //   .row {
      //     .mc-input,
      //     .mc-textarea {
      //       max-width: 944px;
      //     }
      //   }

      .mc-input input,
      .mc-textarea textarea {
        font-size: 16px;
        transform: scale(0.625);
        transform-origin: left center;
        width: calc(100% * 1.6);
      }

      .mc-textarea {
        .mc-input__holder {
          height: calc(13rem * 0.625);

          textarea {
            transform-origin: left top;
          }
        }
      }
    }
  }
}

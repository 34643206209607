@import '../../css/breakpoint.scss';

.mc-app-footer {
  width: 100%;
  float: left;
  display: block;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .wrap-link {
    padding: 30px 10px;
    
    a {
      color: #fff;
    }
  }
  
  .wrap {
    display: block;
    width: 100%;
    float: left;
    text-align: center;

    .row {
      width: 100%;
      float: left;
      display: block;
      text-align: center;
    }
  }

  .wrap ~ .wrap {
    margin-top: 0.8rem;
  }

  .wrap-logo {
    background-image: url('../../images/logo.svg');
    background-position: center;
    background-size: auto;
    height: 3rem;
    background-repeat: no-repeat;
    background-size: auto 98%;
  }

  .wrap-contact {
    // font-family: var(--app-font-light);
    font-size: 0.8rem;
    line-height: 1.3;

    span {
      display: inline-block;
      opacity: 0.9;
    }

    span ~ span {
      margin-left: 0.9em;
    }
  }

  .wrap-sharing {
    a.btn-sharing {
      height: 1.8rem;
      width: 1.8rem;
      border: none;
      display: inline-block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      filter: invert(1);
      opacity: 0.6;
      cursor: pointer;
    }

    .btn-sharing-mail {
      background-image: url('../../images/ic-mail.svg');
    }
    .btn-sharing-whatsapp {
      background-image: url('../../images/ic-whatsapp.svg');
      margin-left: 1rem;
    }
  }
}

@media screen and (max-width: $breakpoint_phone) {
  .mc-app-footer {
    .wrap-logo {
    //   height: 1.5;
    }

    .wrap-sharing {
      a.btn-sharing {
        // height: 22px;
        // width: 22px;
      }
    }
  }
}

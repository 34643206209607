@import '../../css/font.define.scss';
@import '../../css/breakpoint.scss';
@import '../../css/animation.scss';

.page-ads-detail {
  .wrap-loading {
    width: 100%;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }

  .btn-white {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }

  .section-banner {
    // height: 420px;
    position: relative;
    margin-top: 1.25rem;

    // .banner-holder {
    //   background-size: contain;
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   left: 0;
    //   right: 0;
    //   top: 0;
    // }
  }

  .section-action {
    text-align: center;
    margin-top: 2.8rem;

    .btn-action {
      display: inline-block;
      margin-left: .75rem;
      margin-right: .75rem;

      span {
        display: inline-block;
        transform: translateY(-1px);
      }

      &.btn-view {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      &.btn-back {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }

  .section-content {
    margin-top: 2rem;
    margin-bottom: 8.5rem;
    text-align: center;

    .row {
      text-align: center;
      float: none;
      display: inline-block;
      width: 100%;
      max-width: 1600px;

      & ~ .row {
        margin-top: 1rem;
      }

      .lb-title,
      .lb-value {
        width: 100%;
        float: left;
        display: block;
        font-size: 1.1rem;
        overflow-wrap: break-word;
        word-break: break-all;
        padding-left: 5rem;
        padding-right: 5rem;
      }

      .lb-title {
        @extend .app-font-bold;
      }
    }
  }
}

@media screen and (max-width: $breakpoint_phone) {
  .page-ads-detail {
    .section-banner {
      margin-top: 0;
    }

    .section-content {
      .row {
        .lb-title,
        .lb-value {
          font-size: 1.5rem;
          padding-left: 3rem;
          padding-right: 3rem;
        }
      }
    }

    .section-action {
      text-align: center;
      margin-top: 2.8rem;

      .btn-action {
        display: inline-block;
        height: auto;

        &.btn-back {
          margin-top: 1rem;
        }
      }
    }
  }
}

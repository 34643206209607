@import '../../css/layout.scss';
@import '../../css/breakpoint.scss';

.mc-filter-banner {
  height: 225px;
  width: 100%;

  @extend .background-bottom-right-cover;

  .wrapper {
    @extend .flex-col-center-clear-fix;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    height: 100%;
    width: 100%;
    position: relative;

    .lb-page-name {
      margin-bottom: 0.9rem;
      margin-top: -0.65rem;
    }
  }
}

@media screen and (max-width: $breakpoint_phone) {
  .mc-filter-banner {
    height: 280px;
    .wrapper {
      justify-content: center;
      align-items: center;

      .lb-page-name {
        font-size: 2rem;
      }
    }
  }
}
